import { trackNavigate } from 'app/actions/AnalyticsGa4Actions';
import { getBrowseConfig } from 'app/api/BrowseApi';
import { METADATA_CATEGORY_TYPE, UI_COMPONENT_TYPE, UI_CONTAINER_TYPE } from 'app/constants/AnalyticsGa4Constants';
import BrowseConstants from 'app/constants/BrowseConstants';

const fetchBrowseConfig = () => {
  return {
    types: [
      BrowseConstants.FETCH_BROWSE_REQUEST,
      BrowseConstants.FETCH_BROWSE_SUCCESS,
      BrowseConstants.FETCH_BROWSE_FAILURE
    ],
    apiRequest: (_state, apiClient) => getBrowseConfig(apiClient)
  };
};

const trackNavLinkClick = ({ itemPosition, linkText, linkUrl, rowPosition, rowTitle }) => {
  return trackNavigate({
    metadata: {
      metadata_category_type: METADATA_CATEGORY_TYPE.NAVIGATION,
      metadata_value: linkUrl
    },
    ui: {
      ui_component_label: linkText,
      ui_component_type: UI_COMPONENT_TYPE.LINK_TEXT,
      ui_container_position: rowPosition,
      ui_container_title: rowTitle,
      ui_container_type: UI_CONTAINER_TYPE.BROWSE_ROW_LIST,
      ui_content_position: itemPosition
    }
  });
};

export { fetchBrowseConfig, trackNavLinkClick };
