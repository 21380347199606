import React from 'react';

import BrowseCategoryPage from 'app/components/browse/category';
import BrowseConstants from 'app/constants/BrowseConstants';
import { makeCategoryIndex } from 'app/helpers/browse';
import connectRoute from 'app/routes/connectRoute';

const BrowseCategoryPageRoute = () => <BrowseCategoryPage />;

// TODO: replace this with an API fetch once we have an API.
const exampleResponse = {
  config: {},
  root: {
    title: 'Genre',
    searchUrl:
      'https://chipublib.demo.bibliocommons.com/v2/search?custom_edit=false&query=genre%3A("Thrillers (Fiction)")   audience%3A"adult" contentclass%3A"FICTION"&searchType=bl&suppress=true&sort=newly_acquired',
    subCategories: [
      {
        title: 'Fiction',
        searchUrl:
          'https://chipublib.stage.bibliocommons.com/v2/search?custom_edit=true&query=audience%3A"adult" contentclass%3A"FICTION" superformat%3A"BOOKS"&searchType=bl&suppress=true',
        subCategories: [
          {
            title: 'Thrillers',
            searchUrl:
              'https://chipublib.stage.bibliocommons.com/v2/search?custom_edit=true&query=(genre%3A(thriller)) audience%3A"adult" contentclass%3A"FICTION" superformat%3ABOOKS isolanguage%3Aeng&searchType=bl&suppress=true&sort=newly_acquired',
            subCategories: []
          },
          {
            title: 'Romance',
            searchUrl:
              'https://chipublib.stage.bibliocommons.com/v2/search?custom_edit=true&query=(genre%3A(romance)) audience%3A"adult" contentclass%3A"FICTION" superformat%3ABOOKS isolanguage%3Aeng&searchType=bl&suppress=true&sort=newly_acquired',
            subCategories: []
          },
          {
            title: 'Mystery and suspense',
            searchUrl:
              'https://chipublib.stage.bibliocommons.com/v2/search?custom_edit=true&query=(genre%3A(mystery) OR genre%3A(suspense)) audience%3A"adult" contentclass%3A"FICTION" superformat%3ABOOKS isolanguage%3Aeng&searchType=bl&suppress=true&sort=newly_acquired',
            subCategories: []
          },
          {
            title: 'Sci-fi and fantasy',
            searchUrl:
              'https://chipublib.stage.bibliocommons.com/v2/search?custom_edit=true&query=(genre%3A(science fiction) OR genre%3A(fantasy)) audience%3A"adult" contentclass%3A"FICTION" superformat%3ABOOKS isolanguage%3Aeng&searchType=bl&suppress=true&sort=relevancy',
            subCategories: []
          },
          {
            title: 'Historical',
            searchUrl:
              'https://chipublib.stage.bibliocommons.com/v2/search?custom_edit=true&query=(genre%3A(historical) OR genre%3A(history)) audience%3A"adult" contentclass%3A"FICTION" superformat%3ABOOKS isolanguage%3Aeng&searchType=bl&suppress=true&sort=relevancy',
            subCategories: []
          },
          {
            title: 'Psychological',
            searchUrl:
              'https://chipublib.stage.bibliocommons.com/v2/search?custom_edit=true&query=(genre%3A(Psychological) OR genre%3A(Psychological)) audience%3A"adult" contentclass%3A"FICTION" superformat%3ABOOKS isolanguage%3Aeng&searchType=bl&suppress=true&sort=relevancy',
            subCategories: []
          },
          {
            title: 'Short stories',
            searchUrl:
              'https://chipublib.stage.bibliocommons.com/v2/search?custom_edit=true&query=(genre%3A(short stories) OR genre%3A(short stories)) audience%3A"adult" contentclass%3A"FICTION" superformat%3ABOOKS isolanguage%3Aeng&searchType=bl&suppress=true&sort=relevancy',
            subCategories: []
          },
          {
            title: 'Drama',
            searchUrl:
              'https://chipublib.stage.bibliocommons.com/v2/search?custom_edit=true&query=(genre%3A(drama) OR genre%3A(drama)) audience%3A"adult" contentclass%3A"FICTION" superformat%3ABOOKS isolanguage%3Aeng&searchType=bl&suppress=true&sort=newly_acquired',
            subCategories: []
          }
        ]
      },
      {
        title: 'Non-Fiction',
        searchUrl:
          'https://chipublib.stage.bibliocommons.com/v2/search?custom_edit=true&query=audience%3A"adult" contentclass%3A"NONFICTION" superformat%3A"BOOKS"&searchType=bl&suppress=true',
        subCategories: [
          {
            title: 'Documentaries',
            searchUrl: '/search?genre=documetaries',
            subCategories: []
          }
        ]
      },
      {
        title: 'For kids',
        searchUrl:
          'https://chipublib.stage.bibliocommons.com/v2/search?custom_edit=true&query=audience%3A"children" contentclass%3A"FICTION" superformat%3A"BOOKS"&searchType=bl&suppress=true&sort=newly_acquired',
        subCategories: []
      },
      {
        title: 'Teen and YA',
        searchUrl:
          'https://chipublib.stage.bibliocommons.com/v2/search?custom_edit=true&query=audience%3A%22teen%22%20contentclass%3A%22FICTION%22%20superformat%3A%22BOOKS%22&searchType=bl&suppress=true&sort=newly_acquired',
        subCategories: []
      },
      {
        title: 'Magazines',
        searchUrl:
          'https://chipublib.stage.bibliocommons.com/v2/search?custom_edit=false&query=formatcode%3A(MAG )&searchType=bl&suppress=true&sort=newly_acquired',
        subCategories: []
      },
      {
        title: 'Movies and TV',
        searchUrl:
          'https://chipublib.stage.bibliocommons.com/v2/search?custom_edit=true&query=audience%3A"adult" superformat%3A"MOVIES_TV_VIDEO"&searchType=bl&suppress=true&sort=relevancy',
        subCategories: []
      },
      {
        title: 'For the holidays',
        searchUrl:
          'https://chipublib.stage.bibliocommons.com/v2/search?custom_edit=true&query=(genre%3A(romance) OR genre%3A(holiday) ) audience%3A"adult" contentclass%3A"FICTION" superformat%3ABOOKS isolanguage%3Aeng&searchType=bl&suppress=true&sort=newly_acquired',
        subCategories: []
      }
    ]
  }
};

const getRouteData = ({ dispatch, state }) => {
  if (!state.getIn(['browse', 'categoryIndex']).isEmpty()) {
    return [];
  }

  return [
    dispatch({
      type: BrowseConstants.SET_CATEGORY_INDEX,
      payload: { categoryIndex: makeCategoryIndex([exampleResponse.root]) }
    })
  ];
};

export default connectRoute(BrowseCategoryPageRoute, { getRouteData });
