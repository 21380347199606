import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { ChevronRight } from '@bibliocommons/base-icons';
import { catalogBibShape } from '@bibliocommons/bc-prop-types';
import Heading from '@bibliocommons/heading';

import { BibSlide } from 'app/components/bibs/shared/BibSlide';

import './BibsRow.scss';

const BibsRow = ({ catalogBibsByMetadataId, metadataIds, title, trackItemClick, trackTitleClick, url }) => {
  if (!metadataIds.size) {
    return null;
  }

  return (
    <div className="cp-bibs-row row">
      <div className="col-12">
        <Heading tagName="h2" size="moderate" variant>
          <a href={url} onAuxClick={trackTitleClick} onClick={trackTitleClick}>
            {title}
            <ChevronRight />
          </a>
        </Heading>
        <ul className="bibs-row__list">
          {metadataIds
            .filter(metadataId => !catalogBibsByMetadataId.get(metadataId, Immutable.Map()).isEmpty())
            .map((metadataId, index) => {
              const catalogBib = catalogBibsByMetadataId.get(metadataId, Immutable.Map());
              const itemPosition = index + 1; // + 1 so that the count is 1-based.

              return (
                <li className="bibs-row__item" key={metadataId}>
                  <BibSlide catalogBib={catalogBib} handleClick={() => trackItemClick({ catalogBib, itemPosition })} />
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

BibsRow.propTypes = {
  catalogBibsByMetadataId: ImmutablePropTypes.mapOf(catalogBibShape, PropTypes.string).isRequired,
  metadataIds: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  trackItemClick: PropTypes.func.isRequired,
  trackTitleClick: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired
};

export default BibsRow;
