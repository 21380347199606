import { CONTENT_GROUP } from 'app/constants/AnalyticsGa4Constants';

export function getContentGroup(currentUrl) {
  let contentGroup;

  switch (true) {
    case currentUrl.startsWith('/v2/accountmessages'):
      contentGroup = CONTENT_GROUP.BORROWING_LIBRARY_MESSAGES;
      break;

    case currentUrl.startsWith('/v2/browse'):
      if (currentUrl === '/v2/browse') {
        contentGroup = CONTENT_GROUP.BROWSE_HOME;
      } else if (currentUrl.startsWith('/v2/browse/titles/new')) {
        contentGroup = CONTENT_GROUP.BROWSE_TITLES_NEW;
      } else if (currentUrl.startsWith('/v2/browse/titles/onorder')) {
        contentGroup = CONTENT_GROUP.BROWSE_TITLES_ON_ORDER;
      }
      break;

    case currentUrl.startsWith('/v2/checkedout'):
      contentGroup = CONTENT_GROUP.BORROWING_CHECKED_OUT;
      break;

    case currentUrl.startsWith('/v2/comment/'):
      contentGroup = CONTENT_GROUP.COMMUNITY_CONTENT_COMMENT;
      break;

    case currentUrl.startsWith('/v2/fines'):
      contentGroup = CONTENT_GROUP.BORROWING_FEES;
      break;

    case currentUrl.startsWith('/v2/interlibraryloans'):
      contentGroup = CONTENT_GROUP.BORROWING_ILL_REQUESTS;
      break;

    case currentUrl.startsWith('/v2/list/display'):
      contentGroup = CONTENT_GROUP.LIST;
      break;

    case currentUrl.startsWith('/v2/locations'):
      contentGroup = CONTENT_GROUP.LOCATION_LISTING;
      break;

    case currentUrl.startsWith('/v2/quotation/'):
      contentGroup = CONTENT_GROUP.COMMUNITY_CONTENT_QUOTATION;
      break;

    case currentUrl.startsWith('/v2/recentlyreturned'):
      contentGroup = CONTENT_GROUP.BORROWING_HISTORY;
      break;

    case currentUrl.startsWith('/v2/record/'): {
      const segments = currentUrl.split('/').filter(Boolean);
      if (segments.length === 3) {
        contentGroup = CONTENT_GROUP.BIB;
      } else {
        const lastSegment = segments[3];
        const groupMapping = {
          lists: CONTENT_GROUP.BIB_LIST,
          recommendations: CONTENT_GROUP.BIB_RECOMMENDATION,
          comments: CONTENT_GROUP.BIB_COMMENT,
          quotations: CONTENT_GROUP.BIB_QUOTATION,
          summaries: CONTENT_GROUP.BIB_SUMMARY,
          videos: CONTENT_GROUP.BIB_VIDEO,
          contentnotices: CONTENT_GROUP.BIB_CONTENT_NOTICE,
          reviews: CONTENT_GROUP.BIB_REVIEW
        };

        contentGroup = groupMapping[lastSegment] || contentGroup;
      }
      break;
    }

    case currentUrl.startsWith('/v2/search'):
      contentGroup = CONTENT_GROUP.CATALOG_SEARCH;
      break;

    case currentUrl.startsWith('/v2/summary/'):
      contentGroup = CONTENT_GROUP.COMMUNITY_CONTENT_SUMMARY;
      break;

    case currentUrl.startsWith('/v2/users/') && currentUrl.endsWith('/shelves/completed'):
      contentGroup = CONTENT_GROUP.SHELVES_COMPLETED;
      break;

    case currentUrl.startsWith('/v2/users/') && currentUrl.endsWith('/shelves/for_later'):
      contentGroup = CONTENT_GROUP.SHELVES_FOR_LATER;
      break;

    case currentUrl.startsWith('/v2/users/') && currentUrl.endsWith('/shelves/in_progress'):
      contentGroup = CONTENT_GROUP.SHELVES_IN_PROGRESS;
      break;

    case currentUrl.startsWith('/v2/video/'):
      contentGroup = CONTENT_GROUP.COMMUNITY_CONTENT_VIDEO;
      break;

    case currentUrl.startsWith('/v2/events'): {
      if (currentUrl.startsWith('/v2/events/account')) {
        contentGroup = CONTENT_GROUP.USER_ACCOUNT_EVENT;
      } else {
        contentGroup = CONTENT_GROUP.EVENT_LISTING;
      }
      break;
    }

    case currentUrl.startsWith('/v2/holds'):
      contentGroup = CONTENT_GROUP.BORROWING_ON_HOLD;
      break;

    default:
      // contentGroup remains undefined if no conditions match
      break;
  }

  return contentGroup;
}
