import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { CREATIVE_TYPES } from 'app/constants/BibConstants';

export default ImmutablePropTypes.mapContains({
  '@graph': ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      '@context': PropTypes.oneOf(['http://schema.org']).isRequired,
      '@type': PropTypes.oneOf(['ItemList', 'Product', ...Object.values(CREATIVE_TYPES)]).isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
});
