import { performCatalogSearch } from 'app/actions/SearchActions';
import { BROWSE_TITLES_PAGE_FACET_FIELD_IDS, SUB_PAGE_BASE_QUERY, SUB_PAGE_SLUG } from 'app/constants/BrowseConstants';
import { mapQuerySearchParamsToCatalogSearchFilters } from 'app/helpers/browse';
import connectRoute from 'app/routes/connectRoute';

const BrowseTitlesOnOrderPageRoute = () => null;

const getRouteData = ({ dispatch, location }) => {
  const searchParams = new URLSearchParams(location.search);
  const catalogSearchFilters = mapQuerySearchParamsToCatalogSearchFilters(searchParams, {
    fieldIds: BROWSE_TITLES_PAGE_FACET_FIELD_IDS
  });

  return [
    dispatch(
      performCatalogSearch({
        ...SUB_PAGE_BASE_QUERY[SUB_PAGE_SLUG.ON_ORDER],
        ...catalogSearchFilters
      })
    )
  ];
};

export default connectRoute(BrowseTitlesOnOrderPageRoute, {
  debounceOptions: {
    wait: 500 // milliseconds
  },
  getRouteData
});
