import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { TertiarySolidLink } from '@bibliocommons/base-links';
import { childrenShape } from '@bibliocommons/bc-prop-types';
import Heading from '@bibliocommons/heading';

import BackToLink from 'app/components/shared/BackToLink';
import Tabs from 'app/components/shared/Tabs';

import './BrowseTitlesPage.scss';

/*
Note: rendering the {children}, even if null, is required to trigger the fetching of route data
in the corresponding connected page route.
*/

const BrowseTitlesPage = ({
  children,
  navItems,
  pageTitle,
  renderFilterableDisplayBibGrid,
  renderMobileMenuOverlay,
  tabLinks,
  trackNavLinkClick
}) => {
  const renderPanelBody = () => {
    return (
      <section>
        {renderFilterableDisplayBibGrid()}
        {children}
      </section>
    );
  };

  const tabOptions = tabLinks.map(tabLink => {
    const { id, text: label, to: url } = tabLink;

    return {
      id,
      label,
      renderPanelBody,
      url
    };
  });

  return (
    <div className="cp-browse-titles-page container">
      <header>
        <div className="row nav-row">
          <div className="col-6 col-md-3">
            <BackToLink href="/" pageId="browse" />
          </div>
          <div className="col-6 col-md-9">
            <div className="mobile-menu">{renderMobileMenuOverlay()}</div>

            <nav className="nav">
              <ul className="nav-list">
                {navItems.map(navItem => {
                  const { id, isActive, text, url } = navItem;
                  return (
                    <li className="nav-item" key={id}>
                      <TertiarySolidLink
                        className={cn('nav-link', {
                          'nav-link--active': isActive
                        })}
                        dataKey={id}
                        href={url}
                        handleClick={() => trackNavLinkClick(`${window.location.origin}${url}`, text)}
                      >
                        {text}
                      </TertiarySolidLink>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Heading id="NewAndOnOrder" size="large" tagName="h1" variant>
              {pageTitle}
            </Heading>
          </div>
        </div>
      </header>

      <article className="row">
        <div className="col-md-12">
          <Tabs ariaLabelledBy="#NewAndOnOrder" asNav centered options={tabOptions} />
        </div>
      </article>
    </div>
  );
};

BrowseTitlesPage.propTypes = {
  children: childrenShape.isRequired,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired,
  pageTitle: PropTypes.string.isRequired,
  renderFilterableDisplayBibGrid: PropTypes.func.isRequired,
  renderMobileMenuOverlay: PropTypes.func.isRequired,
  tabLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired
    })
  ).isRequired,
  trackNavLinkClick: PropTypes.func.isRequired
};

export default BrowseTitlesPage;
