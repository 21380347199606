import createReduxConstants from 'app/helpers/redux/createReduxConstants';

export const BROWSE_ROW_TYPE = {
  BIB: 'BIB',
  LINK: 'LINK',
  LIST: 'LIST',
  NAV: 'NAV'
};

export const NAV_ITEM_ID = {
  AWARDS: 'Awards',
  BEST_SELLERS: 'BestSellers',
  LANGUAGES: 'Languages',
  NEW_AND_ON_ORDER: 'NewAndOnOrder', // Not in Gateway's Settings response
  STAFF_PICKS: 'StaffPicks',
  TEACHER_PICKS: 'TeacherPicks'
};

export const SUB_PAGE_SLUG = {
  NEW: 'new',
  ON_ORDER: 'onorder'
};

export const SUB_PAGE_BASE_QUERY = {
  [SUB_PAGE_SLUG.NEW]: {
    f_ON_ORDER: 'false',
    limit: 18,
    query: 'nw:[0 TO 180]',
    searchType: 'bl',
    sort: 'NEWLY_ACQUIRED'
  },
  [SUB_PAGE_SLUG.ON_ORDER]: {
    f_ON_ORDER: 'true',
    limit: 18,
    query: 'nw:[0 TO 180]',
    searchType: 'bl',
    sort: 'NEWLY_ACQUIRED'
  }
};

export default createReduxConstants('BROWSE', {
  FETCH_BROWSE_REQUEST: null,
  FETCH_BROWSE_SUCCESS: null,
  FETCH_BROWSE_FAILURE: null,
  SET_CATEGORY_INDEX: null
});

// facets
export const MAX_NUM_SHOWN_FILTERS_IN_CONDENSED_VIEW = 15;

export const FIELD_ID = {
  AUDIENCE: 'AUDIENCE',
  AUTHOR: 'AUTHOR',
  FICTION_TYPE: 'FICTION_TYPE',
  FORMAT: 'FORMAT',
  GENRE: 'GENRE_HEADINGS',
  LANGUAGE: 'LANGUAGE',
  RATING: 'UGC_RATING',
  RECENT: 'NEWLY_ACQUIRED',
  SCOPE: 'SCOPE',
  TOPIC: 'TOPIC_HEADINGS'
};

export const FILTERS_TYPE = {
  BUTTONS_AND_PILLS: 'buttons-and-pills',
  CHECKBOXES: 'checkboxes',
  RADIO_BUTTONS: 'radio-buttons'
};

export const GROUP_ID = {
  // FIELD_ID.FORMAT
  ACCESSIBLE_FORMATS: 'ACCESSIBLE_FORMATS',
  AUDIOBOOKS_SPOKEN_WORD: 'AUDIOBOOKS_SPOKEN_WORD',
  BOOKS: 'BOOKS',
  GAMES_INTERACTIVE_MEDIA: 'GAMES_INTERACTIVE_MEDIA',
  JOURNALS_MAGAZINES: 'JOURNALS_MAGAZINES',
  MOVIES_TV_VIDEO: 'MOVIES_TV_VIDEO',
  MUSIC_SOUND: 'MUSIC_SOUND',
  OTHER: 'OTHER',
  // UNGROUPED
  UNGROUPED: 'UNGROUPED'
};

export const FILTERS_TYPE_BY_FIELD_ID = {
  [FIELD_ID.AUDIENCE]: FILTERS_TYPE.CHECKBOXES,
  [FIELD_ID.AUTHOR]: FILTERS_TYPE.BUTTONS_AND_PILLS,
  [FIELD_ID.FICTION_TYPE]: FILTERS_TYPE.BUTTONS_AND_PILLS,
  [FIELD_ID.FORMAT]: FILTERS_TYPE.CHECKBOXES,
  [FIELD_ID.GENRE]: FILTERS_TYPE.BUTTONS_AND_PILLS,
  [FIELD_ID.LANGUAGE]: FILTERS_TYPE.CHECKBOXES,
  [FIELD_ID.RATING]: FILTERS_TYPE.BUTTONS_AND_PILLS,
  [FIELD_ID.RECENT]: FILTERS_TYPE.BUTTONS_AND_PILLS,
  [FIELD_ID.SCOPE]: FILTERS_TYPE.RADIO_BUTTONS,
  [FIELD_ID.TOPIC]: FILTERS_TYPE.BUTTONS_AND_PILLS
};

export const BROWSE_CATEGORY_PAGE_FACET_FIELD_IDS = [
  FIELD_ID.FORMAT,
  FIELD_ID.AUDIENCE,
  FIELD_ID.LANGUAGE,
  FIELD_ID.FICTION_TYPE,
  FIELD_ID.GENRE,
  FIELD_ID.TOPIC,
  FIELD_ID.AUTHOR,
  FIELD_ID.RATING
];

export const BROWSE_TITLES_PAGE_FACET_FIELD_IDS = [
  FIELD_ID.SCOPE,
  FIELD_ID.FORMAT,
  FIELD_ID.FICTION_TYPE,
  FIELD_ID.AUDIENCE,
  FIELD_ID.LANGUAGE
];
