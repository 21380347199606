import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React from 'react';

import RadioButton from '@bibliocommons/base-radio-button';

import { filterShape } from 'app/shapes/browse';

import './RadioButtons.scss';

// The isMultiColumn prop is here to be consistent with the Checkboxes filter.
// It may be used later for mulit-column styling.
/* eslint-disable-next-line no-unused-vars */
const RadioButtons = ({ filters, handleFilterChange, isMultiColumn, isShowAll }) => {
  return (
    <ul className="cp-facets-group-filters-radio-buttons">
      {filters.map(filter => {
        const { isApplied, isShown, filterId, groupId, label: filterLabel } = filter;
        if (!(isShowAll || isShown)) {
          return null;
        }

        const inputId = uniqueId(`FilterRadioButton_${filterId}`);

        return (
          <li className="list-item" key={filterId}>
            <RadioButton
              checked={isApplied}
              className="radio-button"
              id={inputId}
              label={filterLabel}
              name={groupId}
              handleChange={() => handleFilterChange(filter, true)}
            />
          </li>
        );
      })}
    </ul>
  );
};

RadioButtons.propTypes = {
  filters: PropTypes.arrayOf(filterShape).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  isMultiColumn: PropTypes.bool.isRequired,
  isShowAll: PropTypes.bool.isRequired
};

export default RadioButtons;
