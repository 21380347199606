import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { TertiarySolidLink } from '@bibliocommons/base-links';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';

import './NavRow.scss';

export default function NavRow({ links, title, trackClick }) {
  function renderNavLinks(link, index) {
    const itemPosition = index + 1; // 1-based count for analytics

    const { isRouterLink, title: linkText, url: linkUrl } = link;

    return (
      <li className="nav-item" key={linkText}>
        {isRouterLink ? (
          <Link className="nav-link" dataKey="nav-router-link" to={linkUrl}>
            {linkText}
          </Link>
        ) : (
          <TertiarySolidLink
            className="nav-link"
            dataKey="nav-standard-link"
            handleClick={() => trackClick({ itemPosition, linkText, linkUrl })}
            href={linkUrl}
            size="large"
          >
            {linkText}
          </TertiarySolidLink>
        )}
      </li>
    );
  }

  return (
    <>
      <div className="cp-nav-row">
        <ScreenReaderMessage>
          <h2>{title}</h2>
        </ScreenReaderMessage>
        <ul className="nav-row-contents">{links.map(renderNavLinks)}</ul>
      </div>
    </>
  );
}

NavRow.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      isRouterLink: PropTypes.bool,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  trackClick: PropTypes.func.isRequired
};
