import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export default ImmutablePropTypes.mapContains({
  otherScript: ImmutablePropTypes.listOf(PropTypes.string),
  otherScriptSearchQuery: PropTypes.string,
  searchQuery: PropTypes.string,
  searchType: PropTypes.string,
  values: ImmutablePropTypes.listOf(PropTypes.string).isRequired
});
