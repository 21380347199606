import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export default ImmutablePropTypes.mapContains({
  ageSuitabilities: ImmutablePropTypes.mapContains({
    average: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
  }).isRequired,
  comments: ImmutablePropTypes.mapContains({
    total: PropTypes.number.isRequired
  }).isRequired,
  contentAdvisories: ImmutablePropTypes.mapContains({
    total: PropTypes.number.isRequired,
    totalUsers: PropTypes.number.isRequired,
    totalsByType: ImmutablePropTypes.mapContains({
      frightening_scenes: PropTypes.number.isRequired,
      graphic_violence: PropTypes.number.isRequired,
      offensive_language: PropTypes.number.isRequired,
      other: PropTypes.number.isRequired,
      sexual_content: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  quotations: ImmutablePropTypes.mapContains({
    total: PropTypes.number.isRequired
  }).isRequired,
  summaries: ImmutablePropTypes.mapContains({
    total: PropTypes.number.isRequired
  }).isRequired,
  videos: ImmutablePropTypes.mapContains({
    total: PropTypes.number.isRequired
  }).isRequired
});
