import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TextButton } from '@bibliocommons/base-buttons';
import Heading from '@bibliocommons/heading';
import { Pill } from '@bibliocommons/pill';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';

import { fieldShape } from 'app/shapes/browse';
import { getUniqueAppliedFilters } from 'app/helpers/browse';

import './AppliedFilters.scss';

const SelectedFilterPills = ({
  accessibilityHeadingMessage,
  accessibilityHeadingTag,
  fields,
  handleFilterChange,
  unapplyAllFilters
}) => {
  const uniqueAppliedFilters = getUniqueAppliedFilters(fields);
  if (!uniqueAppliedFilters.length) {
    return null;
  }

  return (
    <div className="cp-facets-applied-filters">
      <ScreenReaderMessage>
        <Heading tagName={accessibilityHeadingTag}>{accessibilityHeadingMessage}</Heading>
      </ScreenReaderMessage>
      {uniqueAppliedFilters.map(filter => (
        <Pill className="pill" handleDismiss={() => handleFilterChange(filter, false)} key={filter.filterId}>
          {filter.label}
        </Pill>
      ))}
      <TextButton className="clear-all" dataKey="facets-active-filters-clear-all" handleClick={unapplyAllFilters}>
        <FormattedMessage id="clear_filters" />
      </TextButton>
    </div>
  );
};

SelectedFilterPills.propTypes = {
  accessibilityHeadingMessage: PropTypes.string.isRequired,
  accessibilityHeadingTag: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(fieldShape).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  unapplyAllFilters: PropTypes.func.isRequired
};

export default SelectedFilterPills;
