import { createSelector } from 'reselect';
import Immutable from 'immutable';
import { selectCurrentLibrary } from './LibrarySelector';

export const allSites = state => state.getIn(['entities', 'sites']);

export const selectCurrentSiteScopes = createSelector(selectCurrentLibrary, allSites, (library, sites) => {
  return sites.getIn([library.get('siteId').toString(), 'scopes'], Immutable.List());
});

export const selectDefaultScopeId = createSelector(selectCurrentLibrary, allSites, (library, sites) => {
  return sites.getIn([library.get('siteId').toString(), 'defaultScopeId']);
});
