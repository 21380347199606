import cn from 'classnames';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from '@bibliocommons/base-checkbox';

import { filterShape } from 'app/shapes/browse';

import './Checkboxes.scss';

const Checkboxes = ({ filters, handleFilterChange, isMultiColumn, isShowAll }) => {
  return (
    <ul
      className={cn('cp-facets-group-filters-checkboxes', {
        'cp-facets-group-filters-checkboxes--multi-column': isMultiColumn
      })}
    >
      {filters.map(filter => {
        const { isApplied, isShown, filterId, label: filterLabel } = filter;
        if (!(isShowAll || isShown)) {
          return null;
        }

        const inputId = uniqueId(`FilterCheckbox_${filterId}`);

        return (
          <li className="list-item" key={filterId}>
            <Checkbox
              className="checkbox"
              id={inputId}
              label={filterLabel}
              checked={isApplied}
              handleChange={() => handleFilterChange(filter, !isApplied)}
            />
          </li>
        );
      })}
    </ul>
  );
};

Checkboxes.propTypes = {
  filters: PropTypes.arrayOf(filterShape).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  isMultiColumn: PropTypes.bool.isRequired,
  isShowAll: PropTypes.bool.isRequired
};

export default Checkboxes;
