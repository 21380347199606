import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Badge } from '@bibliocommons/base-badges';
import { TextButton } from '@bibliocommons/base-buttons';
import { ChevronDown, ChevronRight } from '@bibliocommons/base-icons';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import Heading from '@bibliocommons/heading';

import { fieldShape } from 'app/shapes/browse';
import Group from '../Group';

import './Field.scss';

const Field = ({ field, handleFilterChange, handleShowMoreClick }) => {
  const { fieldId, groups, hasMore, isInitiallyExpanded, label, numAppliedFilters } = field;

  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);

  return (
    <fieldset className="cp-facets-field" key={fieldId}>
      <button
        aria-expanded={isExpanded}
        className="expander"
        onClick={() => setIsExpanded(isExpandedState => !isExpandedState)}
        type="button"
      >
        {isExpanded ? <ChevronDown className="icon-toggle" /> : <ChevronRight className="icon-toggle" />}{' '}
        <Heading className="heading" size="modest" tagName="legend">
          <span className="label">{label}</span>{' '}
          {numAppliedFilters > 0 && (
            <Badge size="small" rounded>
              {numAppliedFilters}
            </Badge>
          )}
        </Heading>
      </button>
      {isExpanded && (
        <div className="groups">
          {groups.map(group => {
            return (
              <Group
                group={group}
                handleFilterChange={handleFilterChange}
                isMultiColumn={false}
                isShowAll={false}
                key={group.groupId}
              />
            );
          })}
          {hasMore && (
            <TextButton
              className="show-more"
              dataKey={`show-more-${fieldId}`}
              handleClick={() => handleShowMoreClick(fieldId)}
              rightAligned
            >
              <span aria-hidden>
                <FormattedMessage id="button_show_more" />
              </span>
              <ScreenReaderMessage>
                <FormattedMessage id="sr_show_more_opens_overlay" />
              </ScreenReaderMessage>
            </TextButton>
          )}
        </div>
      )}
    </fieldset>
  );
};

Field.propTypes = {
  field: fieldShape.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleShowMoreClick: PropTypes.func.isRequired
};

export default Field;
