import PropTypes from 'prop-types';
import React from 'react';

import { TextButton } from '@bibliocommons/base-buttons';
import { Pill } from '@bibliocommons/pill';

import { filterShape } from 'app/shapes/browse';
import { groupFiltersByIsApplied } from 'app/helpers/browse';

import './ButtonsAndPills.scss';

// The isMultiColumn prop is here to be consistent with the Checkboxes filter.
// It may be used later for mulit-column styling.
/* eslint-disable-next-line no-unused-vars */
const ButtonsAndPills = ({ filters, handleFilterChange, isMultiColumn, isShowAll }) => {
  const { applied: appliedFilters, unapplied: unappliedFilters } = groupFiltersByIsApplied(filters);

  const hasAppliedFilters = appliedFilters.length > 0;
  const hasUnappliedFilters = unappliedFilters.length > 0;

  return (
    <div className="cp-facets-groups-filters-buttons-pills">
      {hasAppliedFilters && (
        <ul className="list">
          {appliedFilters.map(filter => {
            const { filterId, label } = filter;
            return (
              <li className="item item--applied" key={filterId}>
                <Pill key={filterId} handleDismiss={() => handleFilterChange(filter, false)}>
                  {label}
                </Pill>
              </li>
            );
          })}
        </ul>
      )}

      {hasUnappliedFilters && (
        <ul className="list">
          {unappliedFilters.map(filter => {
            const { isShown, filterId, label } = filter;
            if (!(isShowAll || isShown)) {
              return null;
            }

            return (
              <li key={filterId}>
                <TextButton
                  className="unapplied-filter"
                  dataKey={filterId}
                  handleClick={() => handleFilterChange(filter, true)}
                  leftAligned
                  type="button"
                >
                  {label}
                </TextButton>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

ButtonsAndPills.propTypes = {
  filters: PropTypes.arrayOf(filterShape).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  isMultiColumn: PropTypes.bool.isRequired,
  isShowAll: PropTypes.bool.isRequired
};

export default ButtonsAndPills;
