import moment from 'moment';

import { setCookie } from '@bibliocommons/utils-browser';

import SearchApi from '../api/SearchApi';
import SearchConstants, { SEARCH_VIEW_COOKIE_NAME } from '../constants/SearchConstants';

const GROUPING_COOKIE = 'catalog_search_show_groupings';

function performCatalogSearch(searchQuery) {
  return {
    searchQuery,
    types: [SearchConstants.SEARCH_REQUEST, SearchConstants.SEARCH_SUCCESS, SearchConstants.SEARCH_FAILURE],
    apiRequest: (state, apiClient) => SearchApi.fetchCatalogResults(searchQuery, apiClient)
  };
}

function performShelfAvailabilitySearch(searchQuery) {
  return {
    searchQuery,
    types: [
      SearchConstants.SHELF_AVAILABILITY_SEARCH_REQUEST,
      SearchConstants.SHELF_AVAILABILITY_SEARCH_SUCCESS,
      SearchConstants.SHELF_AVAILABILITY_SEARCH_FAILURE
    ],
    apiRequest: (state, apiClient) =>
      SearchApi.fetchShelfAvailabilityResults(
        {
          ...searchQuery,
          view: 'grouped'
        },
        apiClient
      )
  };
}

function addTitleSearch(searchQuery) {
  return {
    searchQuery,
    types: [
      SearchConstants.ADD_TITLE_SEARCH_REQUEST,
      SearchConstants.ADD_TITLE_SEARCH_SUCCESS,
      SearchConstants.ADD_TITLE_SEARCH_FAILURE
    ],
    apiRequest: (state, apiClient) => SearchApi.fetchCatalogResults({ ...searchQuery, view: 'medium' }, apiClient)
  };
}

function getSearchProbes(query, searchType) {
  return {
    types: [
      SearchConstants.SEARCH_PROBE_REQUEST,
      SearchConstants.SEARCH_PROBE_SUCCESS,
      SearchConstants.SEARCH_PROBE_FAILURE
    ],
    apiRequest: (state, client) => SearchApi.fetchSearchProbes(query, searchType, client)
  };
}

const clearExpandedFieldFilters = () => {
  return {
    type: SearchConstants.CLEAR_EXANDED_FIELD_FILTERS
  };
};

function expandField(fieldId, query) {
  return {
    types: [
      SearchConstants.EXPAND_FIELD_REQUEST,
      SearchConstants.EXPAND_FIELD_SUCCESS,
      SearchConstants.EXPAND_FIELD_FAILURE
    ],
    apiRequest: (_state, apiClient) => SearchApi.fetchAdditionalFilters(query, fieldId, apiClient)
  };
}

function toggleSearchView(view) {
  const expires = moment()
    .add(10, 'years')
    .toDate();
  setCookie(SEARCH_VIEW_COOKIE_NAME, view, { expires });
  return {
    type: SearchConstants.TOGGLE_SEARCH_VIEW,
    view
  };
}

function toggleShowGroupings(newState) {
  setCookie(GROUPING_COOKIE, newState || '');
  return {
    type: SearchConstants.TOGGLE_SHOW_GROUPINGS
  };
}

function getGroupSearchSummaries(metadataIds) {
  return {
    types: [
      SearchConstants.GROUP_SEARCH_SUMMARY_REQUEST,
      SearchConstants.GROUP_SEARCH_SUMMARY_SUCCESS,
      SearchConstants.GROUP_SEARCH_SUMMARY_FAILURE
    ],
    apiRequest: (state, apiClient) =>
      SearchApi.fetchGroupSearchSummaries(metadataIds, state.getIn(['library', 'libraryId']), apiClient)
  };
}

function setShouldPreventImmediateRefetch(shouldPreventImmediateRefetch) {
  return {
    type: SearchConstants.SET_SHOULD_PREVENT_IMMEDIATE_REFETCH,
    payload: {
      shouldPreventImmediateRefetch
    }
  };
}

export default {
  expandField,
  getGroupSearchSummaries,
  getSearchProbes,
  addTitleSearch,
  toggleSearchView,
  toggleShowGroupings,
  performCatalogSearch,
  performShelfAvailabilitySearch,
  setShouldPreventImmediateRefetch
};

export { clearExpandedFieldFilters, expandField, performCatalogSearch };
