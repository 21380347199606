import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { HTTP_STATUS_CODE } from 'app/constants/ErrorConstants';
import ErrorPage from 'app/components/ErrorPage';
import { selectCategoryIndex } from 'app/selectors/BrowseSelector';
import BrowseCategoryPage from './BrowseCategoryPage';
import BrowseCategoryLeafPageContainer from './BrowseCategoryLeafPageContainer';
import BrowseCategoryNodePageContainer from './BrowseCategoryNodePageContainer';

const BrowseCategoryPageContainer = () => {
  const intl = useIntl();
  const params = useParams();
  const categoryIndex = useSelector(selectCategoryIndex);

  const [categoryId] = params ?? [];
  const serializedCategoryId = '/'.concat(categoryId || '').replace(/\/+$/, '');
  const category = categoryIndex.get(serializedCategoryId)?.toJS() ?? null;

  if (!category) {
    return <ErrorPage httpStatusCode={HTTP_STATUS_CODE.NOT_FOUND} />;
  }

  const isCategoryLeaf = category.subCategoryIds.length === 0;
  const parentCategory = categoryIndex.get(category.parentCategoryId)?.toJS() ?? null;
  const backToPageTitle = parentCategory?.title ?? intl.formatMessage({ id: 'browse' });

  const backToLinkProps = {
    href: `/v2/browse${parentCategory?.categoryId ?? ''}`,
    label: backToPageTitle,
    screenReaderLabel: intl.formatMessage(
      {
        defaultMessage: 'Back to {pageTitle}',
        id: 'sr_back_to_page_title'
      },
      {
        pageTitle: backToPageTitle
      }
    )
  };

  return (
    <BrowseCategoryPage backToLinkProps={backToLinkProps} category={category}>
      {isCategoryLeaf ? (
        <BrowseCategoryLeafPageContainer category={category} />
      ) : (
        <BrowseCategoryNodePageContainer category={category} />
      )}
    </BrowseCategoryPage>
  );
};

export default BrowseCategoryPageContainer;
