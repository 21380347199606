import Immutable from 'immutable';
import curry from 'lodash/curry';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { clearExpandedFieldFilters, expandField, performCatalogSearch } from 'app/actions/SearchActions';
import { BROWSE_CATEGORY_PAGE_FACET_FIELD_IDS, FIELD_ID, GROUP_ID } from 'app/constants/BrowseConstants';
import { mapSearchBibToDisplayBib } from 'app/helpers/displayBib';
import {
  makeClearedSearchParams,
  makeFieldLabel,
  makeFilterLabel,
  makeGroupLabel,
  makeNewSearchParams,
  mapCatalogSearchFieldsToNewFacets,
  mapQuerySearchParamsToCatalogSearchFilters
} from 'app/helpers/browse';
import { selectBibEntities } from 'app/selectors/EntitiesSelector';
import { categoryShape } from 'app/shapes/browse';
import FilterableDisplayBibGrid from '../shared/FilterableDisplayBibGrid';

const BrowseCategoryLeafPageContainer = ({ category }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const intl = useIntl();
  const { search } = useLocation();
  const expandedFiltersByFieldId =
    useSelector(state => state.getIn(['search', 'catalogSearch', 'expandedFiltersByFieldId'])) ?? Immutable.Map();
  const fields = useSelector(state => state.getIn(['search', 'catalogSearch', 'fields']));
  const isFetching = useSelector(state => state.getIn(['search', 'catalogSearch', 'isFetching'])) ?? false;
  const pagination = useSelector(state => state.getIn(['search', 'catalogSearch', 'pagination']));
  const searchBibs = useSelector(selectBibEntities);
  const searchResults = useSelector(state => state.getIn(['search', 'catalogSearch', 'results']))?.toJS() ?? [];

  useEffect(() => {
    const searchQueryParams = new URLSearchParams(search);
    const catalogSearchFilters = mapQuerySearchParamsToCatalogSearchFilters(searchQueryParams, {
      fieldIds: BROWSE_CATEGORY_PAGE_FACET_FIELD_IDS
    });

    dispatch(performCatalogSearch({ ...category.queryParams, ...catalogSearchFilters, limit: 18, view: 'grouped' }));
  }, [category, dispatch, search]);

  const searchParams = new URLSearchParams(search);
  const appliedFiltersAccessibilityHeading = intl.formatMessage({ id: 'browse_active_filters' });
  const facetsHeading = intl.formatMessage({
    defaultMessage: 'TODO: What message here?',
    id: 'browse_facets'
  });

  const displayBibs = searchResults
    .map(({ representative: metadataId }) => searchBibs.get(metadataId)?.toJS() ?? null)
    .map(mapSearchBibToDisplayBib);

  const formatFieldLabel = curry(makeFieldLabel)(intl);
  const facetFields = mapCatalogSearchFieldsToNewFacets(fields.toJS(), {
    expandedFiltersByFieldId: expandedFiltersByFieldId?.toJS(),
    formatFieldLabel,
    formatFilterLabel: curry(makeFilterLabel)(intl),
    formatGroupLabel: curry(makeGroupLabel)(intl),
    formatShowMoreOverlayTitle: fieldId => {
      const fieldLabel = formatFieldLabel({ fieldId });
      return intl.formatMessage({ defaultMessage: 'All {fieldLabel}', id: 'all_field_filters' }, { fieldLabel });
    },
    orderedFieldIds: BROWSE_CATEGORY_PAGE_FACET_FIELD_IDS,
    orderedGroupIdsByFieldId: {
      [FIELD_ID.FORMAT]: [
        GROUP_ID.BOOKS,
        GROUP_ID.AUDIOBOOKS_SPOKEN_WORD,
        GROUP_ID.MOVIES_TV_VIDEO,
        GROUP_ID.MUSIC_SOUND,
        GROUP_ID.GAMES_INTERACTIVE_MEDIA,
        GROUP_ID.JOURNALS_MAGAZINES,
        GROUP_ID.ACCESSIBLE_FORMATS,
        GROUP_ID.OTHER
      ]
    },
    searchParams
  });

  const handleFilterChange = (filter, newIsApplied) => {
    const newSearchParams = makeNewSearchParams(searchParams, filter, newIsApplied);

    push(`?${newSearchParams.toString()}`);
  };

  const unapplyAllFilters = () => {
    const newSearchParams = makeClearedSearchParams({
      fieldIds: BROWSE_CATEGORY_PAGE_FACET_FIELD_IDS,
      oldSearchParams: searchParams
    });

    push(`?${newSearchParams.toString()}`);
  };

  const clearMoreFilters = () => {
    dispatch(clearExpandedFieldFilters());
  };

  const fetchMoreFilters = fieldId => {
    dispatch(
      expandField(
        fieldId,
        mapQuerySearchParamsToCatalogSearchFilters(searchParams, { fieldIds: BROWSE_CATEGORY_PAGE_FACET_FIELD_IDS })
      )
    );
  };

  return (
    <FilterableDisplayBibGrid
      appliedFiltersAccessibilityHeading={appliedFiltersAccessibilityHeading}
      clearMoreFilters={clearMoreFilters}
      displayBibs={displayBibs}
      facetFields={facetFields}
      facetsHeading={facetsHeading}
      fetchMoreFilters={fetchMoreFilters}
      handleFilterChange={handleFilterChange}
      hasBibRatings
      isFetching={isFetching}
      pagination={pagination}
      unapplyAllFilters={unapplyAllFilters}
    />
  );
};

BrowseCategoryLeafPageContainer.propTypes = {
  category: categoryShape.isRequired
};

export default BrowseCategoryLeafPageContainer;
