import Immutable from 'immutable';

import BrowseConstants from 'app/constants/BrowseConstants';

const initialState = Immutable.Map({ categoryIndex: Immutable.Map(), config: Immutable.Map(), isFetching: false });

const browseReducer = (state = initialState, action) => {
  switch (action.type) {
    case BrowseConstants.FETCH_BROWSE_REQUEST: {
      return state.set('isFetching', true);
    }

    case BrowseConstants.FETCH_BROWSE_SUCCESS: {
      return state.merge({
        config: Immutable.fromJS(action.payload?.config?.config),
        isFetching: false
      });
    }

    case BrowseConstants.FETCH_BROWSE_FAILURE: {
      return state.set('isFetching', false);
    }

    case BrowseConstants.SET_CATEGORY_INDEX: {
      return state.set('categoryIndex', Immutable.fromJS(action.payload?.categoryIndex));
    }

    default:
      return state;
  }
};

export default browseReducer;
