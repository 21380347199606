import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { SecondaryGhostButton, SecondarySolidButton, TextButton } from '@bibliocommons/base-buttons';
import {
  FullScreenOverlayBody,
  FullScreenOverlayContent,
  FullScreenOverlayFooter,
  FullScreenOverlayHeader
} from '@bibliocommons/base-full-screen-overlay';
import { ArrowBack as ArrowBackIcon, Filter as FilterIcon } from '@bibliocommons/base-icons';
import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';

import { FullScreenOverlayContainer } from 'app/components/shared/FullScreenOverlay';
import { fieldShape } from 'app/shapes/browse';
import { getUniqueAppliedFilters } from 'app/helpers/browse';
import Field from '../shared/Field';
import ShowMoreOverlay from '../shared/ShowMoreOverlay';
import AppliedFilters from '../AppliedFilters';

import './Mobile.scss';

const MobileFacets = ({
  appliedFiltersAccessibilityHeadingMessage,
  clearMoreFilters,
  fetchMoreFilters,
  fields,
  handleFilterChange,
  numTotalResults,
  openOverlayClassName,
  overlayTitle,
  unapplyAllFilters
}) => {
  const [isFacetsOverlayOpen, setIsFacetsOverlayOpen] = useState(false);
  const [showMoreFieldId, setShowMoreFieldId] = useState(null);

  const numAppliedFilters = getUniqueAppliedFilters(fields).length;

  const handleCloseOverlay = () => {
    setIsFacetsOverlayOpen(false);
  };

  const handleCloseShowMore = () => {
    clearMoreFilters();
    setShowMoreFieldId(null);
  };

  const handleShowMoreClick = fieldId => {
    fetchMoreFilters(fieldId);
    setShowMoreFieldId(fieldId);
  };

  const handleBackButtonClick = () => {
    // We need to ensure that the Show More Overlay is popped from the history.
    // Otherwise, @bibliocommons/base-full-screen-overlay becomes out-of-sync
    window.history.back();
    setShowMoreFieldId(null);
  };

  const renderCloseMobileFacetsOverlayButton = () => {
    return (
      <SecondarySolidButton dataKey="facets-mobile-close" handleClick={handleCloseOverlay}>
        <FormattedMessage id="done" />
      </SecondarySolidButton>
    );
  };

  const renderBackButton = () => {
    return (
      <TextButton
        className="back-button"
        dataKey="facets-mobile-back-button"
        handleClick={handleBackButtonClick}
        leftAligned
        renderLeadingIcon={() => <ArrowBackIcon className="icon-back" />}
      >
        <span aria-hidden>
          <FormattedMessage id="all_filters" />
        </span>
        <ScreenReaderMessage>
          <FormattedMessage id="sr_all_filters" />
        </ScreenReaderMessage>
      </TextButton>
    );
  };

  const renderUnapplyAllFiltersButton = () => {
    return (
      <SecondaryGhostButton className="clear-all" dataKey="facets-mobile-clear-all" handleClick={unapplyAllFilters}>
        <FormattedMessage id="clear_filters" />
      </SecondaryGhostButton>
    );
  };

  const showMoreField = fields.find(field => field.fieldId === showMoreFieldId);

  return (
    <div>
      <FullScreenOverlayContainer
        dataKey="facets-mobile-overlay"
        handleClose={handleCloseOverlay}
        open={isFacetsOverlayOpen}
        overlayKey="facets-mobile-overlay"
      >
        <div className="cp-facets-mobile">
          <FullScreenOverlayBody>
            <FullScreenOverlayHeader
              subtitle={<FormattedMessage id="total_results" values={{ total: numTotalResults }} />}
              title={overlayTitle}
            />
            <FullScreenOverlayContent size="small">
              <AppliedFilters
                accessibilityHeadingMessage={appliedFiltersAccessibilityHeadingMessage}
                accessibilityHeadingTag="h3"
                fields={fields}
                handleFilterChange={handleFilterChange}
                unapplyAllFilters={unapplyAllFilters}
              />

              <ul className="field-list">
                {fields.map(field => {
                  return (
                    <li className="field-item" key={field.fieldId}>
                      <Field
                        field={field}
                        handleFilterChange={handleFilterChange}
                        handleShowMoreClick={handleShowMoreClick}
                        key={field.fieldId}
                      />
                    </li>
                  );
                })}
              </ul>
            </FullScreenOverlayContent>
            <FullScreenOverlayFooter
              renderSecondaryAction={renderUnapplyAllFiltersButton}
              renderPrimaryAction={renderCloseMobileFacetsOverlayButton}
            ></FullScreenOverlayFooter>
          </FullScreenOverlayBody>
        </div>
      </FullScreenOverlayContainer>

      {showMoreField && (
        <FullScreenOverlayContainer
          dataKey="mobile-facets-show-more-overlay"
          handleClose={handleCloseShowMore}
          open={Boolean(showMoreField)}
          overlayKey="mobile-facets-show-more-overlay"
        >
          <div className="cp-facets-mobile">
            <ShowMoreOverlay
              group={showMoreField.groups[0]}
              handleFilterChange={handleFilterChange}
              renderBackButton={renderBackButton}
              title={showMoreField.showMoreOverlayTitle}
            />
          </div>
        </FullScreenOverlayContainer>
      )}

      <SecondaryGhostButton
        className={openOverlayClassName}
        dataKey="facets-mobile-open-overlay"
        handleClick={() => setIsFacetsOverlayOpen(true)}
        renderLeadingIcon={() => <FilterIcon />}
      >
        <span aria-hidden>
          <FormattedMessage
            defaultMessage="Filter{count, plural, =0 {} other { (#)}}"
            id="filter_with_count"
            values={{ count: numAppliedFilters }}
          />
        </span>
        <ScreenReaderMessage>
          <FormattedMessage
            defaultMessage="{overlayTitle}{count, plural, =0 {} other {. # filters active}}, opens an overlay"
            id="sr_filter_with_count"
            values={{ count: numAppliedFilters, overlayTitle }}
          />
        </ScreenReaderMessage>
      </SecondaryGhostButton>
    </div>
  );
};

MobileFacets.propTypes = {
  appliedFiltersAccessibilityHeadingMessage: PropTypes.string.isRequired,
  clearMoreFilters: PropTypes.func.isRequired,
  fetchMoreFilters: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(fieldShape).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  numTotalResults: PropTypes.number.isRequired,
  openOverlayClassName: PropTypes.string,
  overlayTitle: PropTypes.string.isRequired,
  unapplyAllFilters: PropTypes.func.isRequired
};

export default MobileFacets;
