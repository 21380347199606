import { SEARCH_FACET_FIELD_ID } from 'app/constants/BibConstants';
import { EVENTS_FACET_FIELDS, MY_EVENTS_FACET_ID } from 'app/constants/EventsConstants';

export const ALL_FACET_FIELD_IDS = Object.values(SEARCH_FACET_FIELD_ID).concat(
  Object.values(EVENTS_FACET_FIELDS),
  Object.values(MY_EVENTS_FACET_ID)
);

export const FILTER_TYPE = {
  BROWSE: 'browse',
  EVENT: 'event'
};

export const FACET_FILTER_UI_TYPE = {
  CHECKBOXES: 'checkbox',
  DATE_RANGE: 'date-range',
  RADIO_BUTTONS: 'radio-buttons',
  SELECTED_PILLS: 'selected-pills'
};

export const UNGROUPED_GROUP_ID = 'UNGROUPED';
export const GROUP_ID = {
  [SEARCH_FACET_FIELD_ID.FORMAT]: {
    ACCESSIBLE_FORMATS: 'ACCESSIBLE_FORMATS',
    AUDIOBOOKS_SPOKEN_WORD: 'AUDIOBOOKS_SPOKEN_WORD',
    BOOKS: 'BOOKS',
    GAMES_INTERACTIVE_MEDIA: 'GAMES_INTERACTIVE_MEDIA',
    JOURNALS_MAGAZINES: 'JOURNALS_MAGAZINES',
    MOVIES_TV_VIDEO: 'MOVIES_TV_VIDEO',
    MUSIC_SOUND: 'MUSIC_SOUND',
    OTHER: 'OTHER'
  }
};

export const FORMAT_GROUP_ORDER = [
  GROUP_ID.FORMAT.BOOKS,
  GROUP_ID.FORMAT.AUDIOBOOKS_SPOKEN_WORD,
  GROUP_ID.FORMAT.MOVIES_TV_VIDEO,
  GROUP_ID.FORMAT.MUSIC_SOUND,
  GROUP_ID.FORMAT.GAMES_INTERACTIVE_MEDIA,
  GROUP_ID.FORMAT.JOURNALS_MAGAZINES,
  GROUP_ID.FORMAT.ACCESSIBLE_FORMATS,
  GROUP_ID.FORMAT.OTHER
];

export const NUM_UNCHECKED_FILTERS_PER_GROUP_SHOWN_IN_CONDENSED_VIEW = 10;
