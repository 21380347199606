import PropTypes from 'prop-types';
import React from 'react';

import { FILTERS_TYPE, GROUP_ID } from 'app/constants/BrowseConstants';
import { groupShape } from 'app/shapes/browse';
import ButtonsAndPills from './filters/ButtonsAndPills';
import Checkboxes from './filters/Checkboxes';
import RadioButtons from './filters/RadioButtons';

import './Group.scss';

const getFiltersComponent = filtersType => {
  switch (filtersType) {
    case FILTERS_TYPE.BUTTONS_AND_PILLS:
      return ButtonsAndPills;
    case FILTERS_TYPE.CHECKBOXES:
      return Checkboxes;
    case FILTERS_TYPE.RADIO_BUTTONS:
      return RadioButtons;
    default:
      throw new Error(`Unknown filters type: ${filtersType}`);
  }
};

const Group = ({ group, handleFilterChange, isMultiColumn, isShowAll }) => {
  const { filters, filtersType, groupId, label: groupLabel } = group;
  const hasGroupLabel = groupId !== GROUP_ID.UNGROUPED;

  const FiltersComponent = getFiltersComponent(filtersType);

  const renderFilters = () => (
    <FiltersComponent
      filters={filters}
      handleFilterChange={handleFilterChange}
      isMultiColumn={isMultiColumn}
      isShowAll={isShowAll}
    />
  );

  return (
    <div className="cp-facets-group" key={groupId}>
      {hasGroupLabel ? (
        <fieldset>
          <legend className="group-label">{groupLabel}</legend>
          {renderFilters()}
        </fieldset>
      ) : (
        renderFilters()
      )}
    </div>
  );
};

Group.propTypes = {
  group: groupShape.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  isMultiColumn: PropTypes.bool.isRequired,
  isShowAll: PropTypes.bool.isRequired
};

export default Group;
