import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { CONTENT_PROVIDERS } from 'app/constants/BibConstants';

export default ImmutablePropTypes.mapContains({
  identifyingVolume: PropTypes.number.isRequired,
  seriesEntries: ImmutablePropTypes.listOf(
    ImmutablePropTypes.mapContains({
      authorAccessPoint: PropTypes.string.isRequired,
      authorName: PropTypes.string.isRequired,
      coverImage: ImmutablePropTypes.mapContains({
        large: PropTypes.string,
        medium: PropTypes.string,
        providerURI: PropTypes.oneOf(Object.values(CONTENT_PROVIDERS)),
        small: PropTypes.string,
        type: PropTypes.string
      }),
      metadataId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      volume: PropTypes.number.isRequired,
      volumeLabel: PropTypes.string
    })
  ).isRequired,
  seriesTitle: PropTypes.string.isRequired,
  totalSeriesEntries: PropTypes.number.isRequired
});
