import cn from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import { catalogBibShape } from '@bibliocommons/bc-prop-types';
import ListCard from '@bibliocommons/list-card';

import { BROWSE_ROW_TYPE } from 'app/constants/BrowseConstants';
import BibsRow from '../shared/rows/BibsRow';
import NavRow from '../shared/rows/NavRow';
import LinkContentRow from './LinkContentRow';

import './BrowsePage.scss';

const listProps = {
  id: PropTypes.number.isRequired,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  listType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  owner: PropTypes.number.isRequired
};

const userProps = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
};

const ListsRow = ({
  listIds,
  listsById,
  title,
  trackListCreatorClick,
  trackListImageClick,
  trackListTitleClick,
  usersById
}) => {
  const [primaryListId, ...secondaryListIds] = listIds;
  const primaryList = listsById[primaryListId];
  const primaryListCreatorUserId = primaryList.owner;
  const primaryListCreatorUser = usersById[primaryListCreatorUserId] || {};

  return (
    <div className="cp-browse-lists-row">
      <ScreenReaderMessage>
        <h2>{title}</h2>
      </ScreenReaderMessage>
      <div className="row">
        <div className="browse-lists-row__primary col-md-7">
          <ListCard
            creator={Immutable.Map(primaryListCreatorUser)}
            handleListImageClick={() => trackListImageClick({ itemPosition: 1, list: primaryList })}
            handleListTitleClick={() => trackListTitleClick({ itemPosition: 1, list: primaryList })}
            largeImage
            library={Immutable.Map({})}
            list={Immutable.Map(primaryList)}
            showDescription
            showUserBadge
            titleTag="h3"
            trackCreatorClick={creatorName => {
              trackListCreatorClick({ creatorName, itemPosition: 1, list: primaryList });
            }}
          />
        </div>

        <div className="browse-lists-row__secondary col-md-5">
          <ul className="browse-lists-row__secondary-items">
            {secondaryListIds
              .filter(listId => Boolean(listsById[listId]))
              .map((listId, index) => {
                const list = listsById[listId];
                const { id, owner: creatorUserId } = list;
                const creatorUser = usersById[creatorUserId] || {};

                // + 2 because we want the primary list to be 1
                const itemPosition = index + 2;

                return (
                  <li className="browse-lists-row__secondary-item" key={id}>
                    <ListCard
                      creator={Immutable.Map(creatorUser)}
                      handleListImageClick={() => {
                        trackListImageClick({ itemPosition, list });
                      }}
                      handleListTitleClick={() => {
                        trackListTitleClick({
                          itemPosition,
                          list
                        });
                      }}
                      horizontal
                      library={Immutable.Map({})}
                      list={Immutable.Map(list)}
                      showUserBadge
                      titleTag="h3"
                      trackCreatorClick={creatorName => trackListCreatorClick({ creatorName, itemPosition, list })}
                    />
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

ListsRow.propTypes = {
  listIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  listsById: PropTypes.objectOf(PropTypes.shape(listProps)).isRequired,
  trackListCreatorClick: PropTypes.func.isRequired,
  trackListImageClick: PropTypes.func.isRequired,
  trackListTitleClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  usersById: PropTypes.objectOf(PropTypes.shape(userProps)).isRequired
};

const BrowsePage = ({
  catalogBibsByMetadataId,
  config,
  listsById,
  pageTitle,
  trackBibItemClick,
  trackBibsRowTitleClick,
  trackLinksRowLinkClick,
  trackListCreatorClick,
  trackListImageClick,
  trackListTitleClick,
  trackNavLinkClick,
  usersById
}) => {
  const rows = config.get('elements', Immutable.List());

  const renderDetails = (row, rowPosition) => {
    const rowTitle = row.getIn(['config', 'title'], row.get('title', ''));

    switch (row.get('type')) {
      case BROWSE_ROW_TYPE.BIB: {
        const title = row.getIn(['config', 'title']);
        const url = row.getIn(['config', 'url']);

        return (
          <BibsRow
            catalogBibsByMetadataId={catalogBibsByMetadataId}
            metadataIds={row.getIn(['config', 'metadataIds'])}
            title={title}
            trackItemClick={itemPayload => trackBibItemClick({ ...itemPayload, rowPosition, rowTitle })}
            trackTitleClick={() => trackBibsRowTitleClick({ linkText: title, linkUrl: url, rowPosition, rowTitle })}
            url={url}
          />
        );
      }
      case BROWSE_ROW_TYPE.LINK:
        return (
          <LinkContentRow
            row={row}
            trackClick={({ itemPosition, linkText, linkUrl }) =>
              trackLinksRowLinkClick({ itemPosition, linkText, linkUrl, rowPosition, rowTitle })}
          />
        );
      case BROWSE_ROW_TYPE.LIST: {
        return (
          <ListsRow
            listIds={row.getIn(['config', 'rowContentIds'], Immutable.List()).toJS()}
            listsById={listsById.toJS()}
            title={rowTitle}
            trackListCreatorClick={itemPayload => trackListCreatorClick({ ...itemPayload, rowPosition, rowTitle })}
            trackListImageClick={itemPayload => trackListImageClick({ ...itemPayload, rowPosition, rowTitle })}
            trackListTitleClick={itemPayload => trackListTitleClick({ ...itemPayload, rowPosition, rowTitle })}
            usersById={usersById.toJS()}
          />
        );
      }
      case BROWSE_ROW_TYPE.NAV:
        return (
          <NavRow
            links={row.getIn(['config', 'links'], Immutable.List()).toJS()}
            title={row.getIn(['config', 'title'])}
            trackClick={({ itemPosition, linkText, linkUrl }) =>
              trackNavLinkClick({ itemPosition, linkText, linkUrl, rowPosition, rowTitle })}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="cp-browse-page">
      <ScreenReaderMessage>
        <h1>{pageTitle}</h1>
      </ScreenReaderMessage>

      {rows.map((row, index) => {
        const type = row.get('type');
        const title = row.get('title', row.getIn(['config', 'title']));
        const hasAlternativeStyle = row.getIn(['config', 'alternateRowStyle']) ?? false;
        const isNav = type === BROWSE_ROW_TYPE.NAV;

        // For analytics, the row position is 1-based
        const rowPosition = index + 1;

        return (
          <section
            className={cn('browse-page__row', {
              'browse-page__row--alt': hasAlternativeStyle,
              'browse-page__row--nav': isNav
            })}
            key={`${type}_${title}`}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">{renderDetails(row, rowPosition)}</div>
              </div>
            </div>
          </section>
        );
      })}
    </div>
  );
};

BrowsePage.propTypes = {
  catalogBibsByMetadataId: ImmutablePropTypes.mapOf(catalogBibShape, PropTypes.string).isRequired,
  config: ImmutablePropTypes.mapContains({
    elements: ImmutablePropTypes.listOf(
      ImmutablePropTypes.mapContains({
        config: ImmutablePropTypes.mapContains({ title: PropTypes.string }).isRequired,
        type: PropTypes.oneOf([BROWSE_ROW_TYPE.BIB, BROWSE_ROW_TYPE.LINK, BROWSE_ROW_TYPE.LIST, BROWSE_ROW_TYPE.NAV])
          .isRequired
      }).isRequired
    )
  }).isRequired,
  listsById: ImmutablePropTypes.mapOf(ImmutablePropTypes.mapContains(listProps), PropTypes.string).isRequired,
  pageTitle: PropTypes.string.isRequired,
  trackBibItemClick: PropTypes.func.isRequired,
  trackBibsRowTitleClick: PropTypes.func.isRequired,
  trackLinksRowLinkClick: PropTypes.func.isRequired,
  trackListCreatorClick: PropTypes.func.isRequired,
  trackListImageClick: PropTypes.func.isRequired,
  trackListTitleClick: PropTypes.func.isRequired,
  trackNavLinkClick: PropTypes.func.isRequired,
  usersById: ImmutablePropTypes.mapOf(ImmutablePropTypes.mapContains(userProps), PropTypes.string).isRequired
};

export default BrowsePage;
