import PropTypes from 'prop-types';

import { FIELD_ID, FILTERS_TYPE, GROUP_ID } from 'app/constants/BrowseConstants';

export const categoryShape = PropTypes.shape({
  categoryId: PropTypes.string.isRequired,
  parentCategoryId: PropTypes.string,
  queryParams: PropTypes.shape({
    query: PropTypes.string.isRequired,
    searchType: PropTypes.string.isRequired,
    sort: PropTypes.string
  }).isRequired,
  subCategoryIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired
});

// facets
const fieldIdShape = PropTypes.oneOf(Object.values(FIELD_ID));
const groupIdShape = PropTypes.oneOf(Object.values(GROUP_ID));

export const filterShape = PropTypes.shape({
  fieldId: fieldIdShape.isRequired,
  filterId: PropTypes.string.isRequired,
  groupId: groupIdShape.isRequired,
  isApplied: PropTypes.bool.isRequired,
  isDefault: PropTypes.bool.isRequired,
  isExclusive: PropTypes.bool.isRequired,
  isShown: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
});

export const groupShape = PropTypes.shape({
  groupId: groupIdShape.isRequired,
  filters: PropTypes.arrayOf(filterShape).isRequired,
  filtersType: PropTypes.oneOf(Object.values(FILTERS_TYPE)).isRequired,
  label: PropTypes.string.isRequired
});

export const fieldShape = PropTypes.shape({
  fieldId: fieldIdShape.isRequired,
  hasMore: PropTypes.bool.isRequired,
  groups: PropTypes.arrayOf(groupShape).isRequired,
  isInitiallyExpanded: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  numAppliedFilters: PropTypes.number.isRequired,
  showMoreOverlayTitle: PropTypes.string.isRequired
});
