import PropTypes from 'prop-types';

import { threeLetterToTwoLetterLangCodes } from '@bibliocommons/constants-languages';

import { AUDIENCE, CONTENT_TYPE, FORMAT_ID, FULFILLMENT_PROVIDER } from 'app/constants/displayBib';

export default PropTypes.shape({
  audience: PropTypes.oneOf(Object.values(AUDIENCE)).isRequired,
  authors: PropTypes.arrayOf(PropTypes.string).isRequired,
  callNumber: PropTypes.string,
  contentType: PropTypes.oneOf(Object.values(CONTENT_TYPE)).isRequired,
  description: PropTypes.string,
  formatId: PropTypes.oneOf(Object.values(FORMAT_ID)).isRequired,
  fulfillmentProvider: PropTypes.oneOf(Object.values(FULFILLMENT_PROVIDER)).isRequired,
  groupKey: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  languageCode: PropTypes.oneOf(Object.values(threeLetterToTwoLetterLangCodes)).isRequired,
  metadataId: PropTypes.string.isRequired,
  publicationStatement: PropTypes.string,
  ratingCount: PropTypes.number.isRequired,
  ratingPercentage: PropTypes.number.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
});
