import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export default ImmutablePropTypes.mapContains({
  '@context': PropTypes.oneOf(['http://schema.org']).isRequired,
  '@type': PropTypes.oneOf(['Library']).isRequired,
  image: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
});
