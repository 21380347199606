import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ScreenReaderMessage from '@bibliocommons/base-screen-reader-message';
import Heading from '@bibliocommons/heading';

import { FullScreenOverlayContainer } from 'app/components/shared/FullScreenOverlay';
import { fieldShape } from 'app/shapes/browse';
import Field from '../shared/Field';
import ShowMoreOverlay from '../shared/ShowMoreOverlay';

import './Desktop.scss';

const DesktopFacets = ({ accessibilityHeading, clearMoreFilters, fetchMoreFilters, fields, handleFilterChange }) => {
  const [showMoreFieldId, setShowMoreFieldId] = useState(null);

  const handleShowMoreOverlayClose = () => {
    clearMoreFilters();
    setShowMoreFieldId(null);
  };

  const handleShowMoreClick = fieldId => {
    fetchMoreFilters(fieldId);
    setShowMoreFieldId(fieldId);
  };

  const showMoreField = fields.find(field => field.fieldId === showMoreFieldId);

  return (
    <div className="cp-facets-desktop">
      <ScreenReaderMessage>
        <Heading tagName="h2">{accessibilityHeading}</Heading>
      </ScreenReaderMessage>
      {!showMoreField && (
        <ul className="field-list">
          {fields.map(field => {
            return (
              <li className="field-item" key={field.fieldId}>
                <Field
                  field={field}
                  handleFilterChange={handleFilterChange}
                  handleShowMoreClick={handleShowMoreClick}
                  key={field.fieldId}
                />
              </li>
            );
          })}
        </ul>
      )}
      {showMoreField && (
        <FullScreenOverlayContainer
          dataKey="facets-show-more-overlay"
          handleClose={handleShowMoreOverlayClose}
          open={Boolean(showMoreField)}
          overlayKey="facets-show-more-overlay"
        >
          <ShowMoreOverlay
            group={showMoreField.groups[0]}
            handleFilterChange={handleFilterChange}
            renderBackButton={() => null}
            title={showMoreField.showMoreOverlayTitle}
          />
        </FullScreenOverlayContainer>
      )}
    </div>
  );
};

DesktopFacets.propTypes = {
  accessibilityHeading: PropTypes.string.isRequired,
  clearMoreFilters: PropTypes.func.isRequired,
  fetchMoreFilters: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(fieldShape).isRequired,
  handleFilterChange: PropTypes.func.isRequired
};

export default DesktopFacets;
