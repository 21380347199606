import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackNavLinkClick as createTrackNavLinkClickAction } from 'app/actions/BrowseActions';
import { mapCategoriesToNavRowLinks } from 'app/helpers/browse';
import { selectCategoryIndex } from 'app/selectors/BrowseSelector';
import { categoryShape } from 'app/shapes/browse';
import NavRow from '../shared/rows/NavRow';

const BrowseCategoryNodePageContainer = ({ category }) => {
  const dispatch = useDispatch();
  const categoryIndex = useSelector(selectCategoryIndex)?.toJS() ?? {};

  const { subCategoryIds } = category;

  // TODO: confirm screen-reader title
  const screenReaderNavTitle = `Browse ${category.title} by category`;

  const links = mapCategoriesToNavRowLinks(subCategoryIds.map(categoryId => categoryIndex[categoryId]).filter(Boolean));

  const trackNavLinkClick = ({ itemPosition, linkText, linkUrl }) => {
    dispatch(
      createTrackNavLinkClickAction({
        itemPosition,
        linkText,
        linkUrl,
        rowPosition: 1,
        rowTitle: screenReaderNavTitle
      })
    );
  };

  return (
    <div>
      <NavRow
        links={links}
        title={screenReaderNavTitle}
        trackClick={({ itemPosition, linkText, linkUrl }) => trackNavLinkClick({ itemPosition, linkText, linkUrl })}
      />
    </div>
  );
};

BrowseCategoryNodePageContainer.propTypes = {
  category: categoryShape.isRequired
};

export default BrowseCategoryNodePageContainer;
