import { threeLetterToTwoLetterLangCodes } from '@bibliocommons/constants-languages';

import { AUDIENCE, CONTENT_TYPE } from 'app/constants/displayBib';

export const getRoundedRatingOutOf5 = ratingPercentage => {
  return Math.round((ratingPercentage / 20) * 10) / 10;
};

export const mapSearchBibToDisplayBib = searchBib => {
  if (!searchBib) {
    return null;
  }

  const { briefInfo, id: metadataId, policy: fulfillmentPolicy } = searchBib;
  const {
    audiences,
    authors,
    callNumber,
    contentType,
    description,
    format: formatId,
    groupKey,
    jacket,
    multiscriptAuthor,
    multiscriptTitle,
    primaryLanguage,
    rating,
    subtitle,
    title
  } = briefInfo;
  const { provider: fulfillmentProvider } = fulfillmentPolicy;

  const imageUrl = jacket.large || jacket.medium || jacket.small || null;

  return {
    audience: audiences?.[0] ?? AUDIENCE.UNKNOWN,
    authors: multiscriptAuthor ? [multiscriptAuthor] : authors,
    callNumber,
    contentType: contentType ?? CONTENT_TYPE.UNDETERMINED,
    description,
    formatId,
    fulfillmentProvider,
    groupKey,
    imageUrl,
    languageCode: threeLetterToTwoLetterLangCodes?.[primaryLanguage] || threeLetterToTwoLetterLangCodes.eng,
    metadataId,
    publicationStatement: null,
    ratingCount: rating?.totalCount ?? 0,
    ratingPercentage: rating?.averageRating ?? 0,
    subtitle,
    title: multiscriptTitle || title
  };
};
