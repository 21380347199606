import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import valuePartShape from './valuePart';

export default ImmutablePropTypes.mapContains({
  contentLabel: PropTypes.string,
  contributor: valuePartShape,
  primary: valuePartShape,
  relationship: valuePartShape,
  secondary: valuePartShape,
  sourceURL: PropTypes.string
});
