import createReduxConstants from 'app/helpers/redux/createReduxConstants';

export const MATERIAL_TYPE_PHYSICAL = 'PHYSICAL';
export const MATERIAL_TYPE_DIGITAL = 'DIGITAL';

export const FORMAT_CHOOSER_MODAL = 'format-chooser-modal';
export const OTHER_LANGUAGES_MODAL = 'other-languages-modal';
export const FILTER_SEPARATOR = '|';
export const FILTER_PREFIX = 'f_';
export const PROBES_AVAILABILITY_GROUP = 'PROBES_AVAILABILITY_GROUP';

export const CREATIVE_TYPES = {
  BOOK: 'Book',
  AUDIOBOOK: 'Audiobook',
  MUSIC_ALBUM: 'MusicAlbum',
  MOVIE: 'Movie',
  CREATIVE_WORK: 'CreativeWork'
};

export const SEARCH_FACET_FIELD_ID = {
  AUDIENCE: 'AUDIENCE',
  AUTHOR: 'AUTHOR',
  CIRC: 'CIRC',
  FICTION_TYPE: 'FICTION_TYPE',
  FORMAT: 'FORMAT',
  GENRE_HEADINGS: 'GENRE_HEADINGS',
  GEO_HEADINGS: 'GEO_HEADINGS',
  I_OWN_THIS: 'I_OWN_THIS',
  IS_PRIVATE: 'IS_PRIVATE',
  LANGUAGE: 'LANGUAGE',
  LANGUAGES: 'LANGUAGES', // TODO: Does this exist in API responses?
  NEWLY_ACQUIRED: 'NEWLY_ACQUIRED',
  ON_ORDER: 'ON_ORDER',
  PUBLISHED_DATE: 'PUBLISHED_DATE',
  PRIMARY_LANGUAGE: 'PRIMARY_LANGUAGE',
  SEARCH_SCOPE: 'SEARCH_SCOPE',
  STATUS: 'STATUS',
  TAGS: 'TAGS',
  TECHNICAL_DIFFICULTY: 'TECHNICAL_DIFFICULTY',
  TOPIC_HEADINGS: 'TOPIC_HEADINGS',
  UGC_RATING: 'UGC_RATING'
};

export const FACET_FIELDS_ORDER = [
  SEARCH_FACET_FIELD_ID.IS_PRIVATE,
  SEARCH_FACET_FIELD_ID.I_OWN_THIS,
  SEARCH_FACET_FIELD_ID.SEARCH_SCOPE,
  SEARCH_FACET_FIELD_ID.STATUS,
  SEARCH_FACET_FIELD_ID.FORMAT,
  SEARCH_FACET_FIELD_ID.NEWLY_ACQUIRED,
  SEARCH_FACET_FIELD_ID.CIRC,
  SEARCH_FACET_FIELD_ID.FICTION_TYPE,
  SEARCH_FACET_FIELD_ID.AUDIENCE,
  SEARCH_FACET_FIELD_ID.GENRE_HEADINGS,
  SEARCH_FACET_FIELD_ID.TOPIC_HEADINGS,
  SEARCH_FACET_FIELD_ID.GEO_HEADINGS,
  SEARCH_FACET_FIELD_ID.AUTHOR,
  SEARCH_FACET_FIELD_ID.LANGUAGES,
  SEARCH_FACET_FIELD_ID.PUBLISHED_DATE,
  SEARCH_FACET_FIELD_ID.UGC_RATING,
  SEARCH_FACET_FIELD_ID.TAGS,
  SEARCH_FACET_FIELD_ID.TECHNICAL_DIFFICULTY
];

export const FACET_FIELDS_ORDER_ONLINE_FIRST = [
  'IS_PRIVATE',
  'I_OWN_THIS',
  'SEARCH_SCOPE',
  'CIRC',
  'STATUS',
  'FORMAT',
  'NEWLY_ACQUIRED',
  'FICTION_TYPE',
  'AUDIENCE',
  'GENRE_HEADINGS',
  'TOPIC_HEADINGS',
  'GEO_HEADINGS',
  'AUTHOR',
  'LANGUAGES',
  'PUBLISHED_DATE',
  'UGC_RATING',
  'TAGS',
  'TECHNICAL_DIFFICULTY'
];

export const AVAILABILITY_GROUP_ORDER = [
  'AVAILABLE_ITEMS',
  'AVAILABLE_BY_REQUEST_ITEMS',
  PROBES_AVAILABILITY_GROUP,
  'LIBRARY_USE_ONLY_ITEMS',
  'RESTRICTED_ACCESS_ITEMS',
  'ON_ORDER',
  'EXTENDED_AVAILABLE_ITEMS',
  'EXTENDED_AVAILABLE_BY_REQUEST_ITEMS',
  'EXTENDED_LIBRARY_USE_ONLY_ITEMS',
  'EXTENDED_RESTRICTED_ACCESS_ITEMS',
  'NOT_AVAILABLE_ITEMS',
  'EXTENDED_NOT_AVAILABLE_ITEMS',
  'IN_LIBRARY'
];

export const CONTENT_PROVIDER_IDS = {
  BIBLIOCOMMONS: 'BIBLIOCOMMONS',
  BOOKISH: 'BOOKISH',
  CONTENT_CAFE: 'CONTENT_CAFE',
  I_DREAM_BOOKS: 'I_DREAM_BOOKS',
  LIBRARY_THING: 'LIBRARY_THING',
  NOVELIST: 'NOVELIST',
  SYNDETICS: 'SYNDETICS',
  SYNDETICS_UNBOUND: 'SYNDETICS_UNBOUND'
};

export const CONTENT_PROVIDERS = {
  [CONTENT_PROVIDER_IDS.BIBLIOCOMMONS]: 'https://bibliocommons.com/',
  [CONTENT_PROVIDER_IDS.CONTENT_CAFE]: 'http://contentcafe2.btol.com/',
  [CONTENT_PROVIDER_IDS.I_DREAM_BOOKS]: 'https://idreambooks.com/',
  [CONTENT_PROVIDER_IDS.LIBRARY_THING]: 'https://www.librarything.com/',
  [CONTENT_PROVIDER_IDS.NOVELIST]: 'https://novselect.ebscohost.com/',
  [CONTENT_PROVIDER_IDS.SYNDETICS]: 'http://syndetics.com/'
};

export default createReduxConstants('BIB', {
  FETCH_CATALOG_BIB_REQUEST: null,
  FETCH_CATALOG_BIB_SUCCESS: null,
  FETCH_CATALOG_BIB_FAILURE: null,
  FETCH_AVAILABILITY_REQUEST: null,
  FETCH_AVAILABILITY_SUCCESS: null,
  FETCH_AVAILABILITY_FAILURE: null,
  FETCH_DIGITAL_FORMATS_REQUEST: null,
  FETCH_DIGITAL_FORMATS_SUCCESS: null,
  FETCH_DIGITAL_FORMATS_FAILURE: null,
  FETCH_USER_TRANSACTIONS_REQUEST: null,
  FETCH_USER_TRANSACTIONS_SUCCESS: null,
  FETCH_USER_TRANSACTIONS_FAILURE: null,
  FETCH_DISCOVERY_REQUEST: null,
  FETCH_DISCOVERY_SUCCESS: null,
  FETCH_DISCOVERY_FAILURE: null,
  FETCH_EVALUATION_REQUEST: null,
  FETCH_EVALUATION_SUCCESS: null,
  FETCH_EVALUATION_FAILURE: null,
  FETCH_UGC_REQUEST: null,
  FETCH_UGC_SUCCESS: null,
  FETCH_UGC_FAILURE: null,
  FETCH_HOLDABLE_ITEMS_REQUEST: null,
  FETCH_HOLDABLE_ITEMS_SUCCESS: null,
  FETCH_HOLDABLE_ITEMS_FAILURE: null,
  GET_MARC_REQUEST: null,
  GET_MARC_SUCCESS: null,
  GET_MARC_FAILURE: null,
  GET_REVIEWS_REQUEST: null,
  GET_REVIEWS_SUCCESS: null,
  GET_REVIEWS_FAILURE: null,
  GET_RECOMMENDATIONS_REQUEST: null,
  GET_RECOMMENDATIONS_SUCCESS: null,
  GET_RECOMMENDATIONS_FAILURE: null
});
